// Import basic packages:
import React, { useState, useContext, useEffect } from 'react';
import './app.css';
import './crud-table.css';
import './entity-search.css';
import './form.css';
import http, { BE_ROOT } from "./system/Communicator";
import jwt_decode from "jwt-decode";
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

// Import translation package:
import i18n from "i18next";
import backend from "i18next-http-backend";
import { useTranslation, initReactI18next } from "react-i18next";

// Import system components:
import Login from './system/Login';
import KioskOffline from './system/KioskOffline';
import Base from './system/Base';
import { BrandingContext }  from './system/BrandingProvider';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from "./system/SnackBarUtils"

// Import fonts:
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import modules:
import modules from './modules/modules'

// We need to include file browser in root so that it's CSS is always present.
// Othervise the priorities starts to get buggy with this library.
import { FileBrowser } from 'chonky';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Make Axios better:
declare module 'axios' {
  export interface AxiosRequestConfig {
    setToken: any;
  }
}

// ======================================================================================================

// Setup translations:
var langArr = ['common'];
for (var i = 0; i < modules.length; i++)
{
	try {
	// @ts-ignore
	var l = modules[i].getLocale();
	if (l)
		langArr.push(l);
	} catch {}
}
i18n
	.use(backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		ns: langArr,
		lng: getLanguage(),
		fallbackLng: "en",
		interpolation: {
			escapeValue: false
		}
	});

// ======================================================================================================
export function save(key: string, value : string | null, toSession = false) : void {
	var obj = toSession? sessionStorage : localStorage;
	if (!value)
		obj.removeItem('dp2_' + key);
	else
		obj.setItem('dp2_' + key, value);
}

export function load(key: string, fromSession = false) : string | null {
	var obj = fromSession? sessionStorage : localStorage;
	const val = obj.getItem('dp2_' + key);
	return val || null;
}

var logoutTimer = null as any;

function setToken(newToken: string | null) : void { 
	save('token', newToken, true);
	document.cookie = `token=${newToken};max-age=1;path=/`;
	// Check when the token expires and set the new timeout:
	if (logoutTimer)
	{
		clearTimeout(logoutTimer);
		logoutTimer = null;
	}
	if (newToken) {
		var decoded = jwt_decode(newToken) as any;
		var exp = decoded['exp'];
		var sec = exp - (Date.now() / 1000) - 5; // Redirect 5 seconds before expire, just to be sure.
		if (sec > 0) {
			document.cookie = `token=${newToken};max-age=${sec + 5};path=/`;
			logoutTimer = setTimeout(
				() => { http.defaults.setToken(null); },
				sec * 1000
			);
		}
	} 
	
	
}
function getToken() : string { return load('token', true) || ''; }

function setLanguage(langCode: string) : void {
	i18n.changeLanguage(langCode);
	save('language', langCode);
}

function getBrowserLanguage()
{
	let lang = navigator.language; //|| navigator.userLanguage;
	// Change cs-CZ to cs:
	lang = (lang.split('-')[0]).toLowerCase();
	return lang;
}

function getLanguage() : string {

	
	return load('language') || getBrowserLanguage() || 'en';
}

export function setPageTitle(s: string)
{
	const t = "DigiPanel";
	if (s)
		document.title = s + " | " + t;
	else
		document.title = t;
}

// Cross-browser polyfill (kind-of-not-really):
// Safari cant' parse date in this format: "2000-01-01 10:00:00".
// There can't be a space so let's put T in it instead - all browsers are fine with that.
export function parseDate(s : string) {
	if (s)
		return new Date((s + "").replace(" ", "T"));
	else
		return new Date();
}

/*axios.interceptors.request.use((config) => {
  config.setToken = setToken;
  return config
})*/

// ======================================================================================================

function useDelayUnmount(isMounted: boolean, delayTime: number) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = window.setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}
	
// ======================================================================================================
function App() {

	// Setup language:
	const [t] = useTranslation(langArr);
	const lang = getLanguage();
	
	const token = getToken();
	let hasToken = token !== "" && token !== null;
	
	//const [userToken, setUserToken] = useState("");
	const [userName, setUserName] = useState("??");
	const [isOffline, setIsOffline] = useState(false);
	const [userID, setUserID] = useState(-2);
	const [loggedInWhen, setLoggedInWhen] = useState(new Date()); // This is used only to show "Log in" instead of "Expired" right after user logs in but still sees the login page for half a second because of animation.
	
	if (userID === -2) {
		setUserName(load("lastUserName", true) || "??");
		setUserID( Number(load("lastUserID", true)) || -1);
	}
	
	const TokenHook = (t : any | null) => {
		if (t !== -1)
		{
			//setUserToken(t!);
			setToken(t!);
			setIsLoginMounted(!t);
			setIsAdminMounted(!(!t));
			if (!(!t))
				setFirstTimeAnim(false);
		} else {
			// Kiosk is offline. Remember the token though.
			setIsOffline(true);
		}
	}
	
	const UserHook = (t : any | null) => {
		if (t) {
			//setUserToken(t!.token);
			setToken(t!.token);
			setUserName(t!.user.name);
			save('lastUserID', t!.user.id, true);
			save('lastUserName', t!.user.name, true);
			setUserID(t!.user.id);
			setLoggedInWhen(new Date());
		} else {
			//setUserToken("");
			setToken(null);
			setUserName("??");
			setUserID(-1);
		}		
		setIsLoginMounted(!t);
		setIsAdminMounted(!(!t));
		if (!(!t))
			setFirstTimeAnim(false);
	}
	http.defaults.setToken = TokenHook;
	
	// Setup branding:
	const { setBranding } = useContext(BrandingContext)
	useEffect(() => {

		setBranding({});
		fetch('branding/branding.json',{
		  headers : { 
			/* We are using preload, which requires exact same headers. And default headers are none. */
			/*'Content-Type': 'application/json',
			'Accept': 'application/json'*/
		   }
		}).then(function(response){
			return response.json();
		  })
		  .then(function(myJson) {
			setBranding(myJson);
		  });

	}, []);
	
	// Setup login transitions:
	const [isLoginMounted, setIsLoginMounted] = useState(!hasToken);
	const [isAdminMounted, setIsAdminMounted] = useState(hasToken);
	const [firstTimeAnim, setFirstTimeAnim] = useState(!hasToken);
	const shouldRenderLoginChild = useDelayUnmount(isLoginMounted, 500);
	const shouldRenderAdminChild = useDelayUnmount(isAdminMounted, 600);
	const mountedLoginStyle = { animation: "inLoginAnimation 500ms ease-in" };
	const unmountedLoginStyle = { animation: "outLoginAnimation 550ms ease-in" };
	
	// Setup file library:
	setChonkyDefaults({ iconComponent: ChonkyIconFA });
	
	let modulesSorted = [ ...modules].sort((x : any, y : any) => { return x.menu().weight < y.menu().weight ? -1 : 1 });
	
	// Next do a fake request to see if backend is connected or not. This will trigger "offline" message if it's not:
	//http.get<object>("/ping"); // this prepends /v1 which we don't want
	http.get(BE_ROOT + "/api/ping?" + Date.now());
	
	return (
		<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={lang}>
		<DndProvider backend={HTML5Backend}>
		{/* Bugfix for the Chonky library. We need an invisible file browsers so that CSS is never unlinked.
			Othervise the priorities starts to get all weird when the CSS is linked and unlinked all the time. */}
		<div style={{display : "none"}}>
			<FileBrowser darkMode={false} files={[]} />
			<FileBrowser darkMode={true} files={[]} />
		</div>
	
		<SnackbarProvider maxSnack={5}>
			<SnackbarUtilsConfigurator />
			
			{isOffline && (<KioskOffline setLanguage={setLanguage} currentLanguage={lang} />) }
			
			{!isOffline && shouldRenderAdminChild && (
				<div className="dp2-wrapper">
					<Base userName={userName} userID={userID} modules={modulesSorted} setUser={UserHook} setLanguage={setLanguage} currentLanguage={lang} />
				</div>
			)}
		
			{!isOffline && shouldRenderLoginChild && (
				<div className="d2-login-wrapper" style={isLoginMounted ? ((!firstTimeAnim && mountedLoginStyle) || {}) : unmountedLoginStyle}>
					<Login setUser={UserHook} setLanguage={setLanguage} currentLanguage={lang} firstTime={firstTimeAnim || (new Date().getTime() - loggedInWhen.getTime() <= 1000)} />
				</div>
			)}
			
		</SnackbarProvider>
		</DndProvider>
	</LocalizationProvider>);
}

export default App;
