import axios from 'axios';
import snackNotifications from "./SnackBarUtils";

//export const BE_ROOT = window.location.search.split('be=')[1] || (process.env.REACT_APP_API_URL || window.location.origin);
export const BE_ROOT = (new URLSearchParams(window.location.search)).get('be') || (process.env.REACT_APP_API_URL || window.location.origin);
export const API_URL = BE_ROOT + '/api/v1'; 

const fetchClient = () => {

  const defaultOptions = {
	//baseURL: "http://digipanel.forpres.cz/api/v1",
	baseURL: API_URL,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
	setToken : () => {}
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request:
instance.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('dp2_token');
    config.headers!.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });
  
	instance.interceptors.response.use(function (response) {

	// Try to grab token from url if possible:
	const urlToken = (new URLSearchParams(window.location.search)).get('token');
	if (urlToken)
		response.config.setToken(urlToken);
	else {
		// Grab the new, refreshed token and save it, if possible:
		if (response.headers && response.headers['x-refreshed-token']) {
			response.config.setToken(response.headers['x-refreshed-token']);
		}
	}
    return response;
  }, function (error) {  
	// Check if it is old token and redirect to login page:	
	if (!(error.response) || !(error.response.request))
	{
		error.config.setToken(-1);
	} else {
		if (error.response.request.status === 401) {
			error.config.setToken(null);
		}
		// If not, just show an error:
		else {
			if(error.response.request.responseURL.indexOf("FE_SILENT=1") === -1)
				snackNotifications.error(error.response.data.error);
		}
	}
  });  

  return instance;
};

export default fetchClient();